import { notify } from "@getwellen/valesco";
import {
  AddressType,
  MedicalConditions,
  MedicalHistory,
  OrderState,
  Product,
  ProductSize
} from "graphql/rails-api";
import {
  orderRoute,
  OsteoboostOrderSlug
} from "pages/order/OsteoboostOrderPage";
import { useOrder } from "pages/order/useOrder";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "./AuthContext";

export const PaidAmountKey = "paidAmount";
export const PaidDateKey = "paidDate";
export const PromoCodeKey = "promoCode";

export const StripeOrderIDKey = "stripeOrderId";
export const StripeLast4Key = "stripePaymentMethodLast4";
export const StripeCardTypeKey = "stripePaymentMethodCardType";

export const PatientKey = "patient";
export const MedicalHistoryKey = "medicalHistory";
export const SizingKey = "sizing";
export const ProductKey = "product";
export const BillingAddressKey = "billingAddress";
export const ShippingAddressKey = "shippingAddress";
export const PrescriptionKey = "prescription";
export const PrescriptionTypeKey = "prescriptionType";
export const PrescriberAddressKey = "prescriberAddress";

export const SubmittedDateKey = "submittedDate";

const forKeyRenames: Map<keyof Order, string> = new Map([
  [StripeLast4Key, StripeLast4Key],
  [StripeCardTypeKey, StripeCardTypeKey],
  [StripeOrderIDKey, StripeOrderIDKey],
  [PatientKey, MedicalHistoryKey],
  [SizingKey, ProductKey],
  [BillingAddressKey, BillingAddressKey],
  [ShippingAddressKey, ShippingAddressKey],
  [PrescriptionKey, PrescriptionTypeKey],
  [PrescriberAddressKey, PrescriberAddressKey],
  [PaidDateKey, PaidDateKey],
  [PaidAmountKey, PaidAmountKey],
  [PromoCodeKey, PromoCodeKey]
]);

export const sizeRename = {
  [ProductSize.Small]: "Small",
  [ProductSize.Medium]: "Medium",
  [ProductSize.Large]: "Large"
};

export type DrugAllergy = {
  none: boolean;
  cephalosporins: boolean;
  clindamycin: boolean;
  codeine: boolean;
  demerol: boolean;
  iodine: boolean;
  macrolides: boolean;
  morphine: boolean;
  nsaidsAspirin: boolean;
  penicillin: boolean;
  sulfonamides: boolean;
  tetracycline: boolean;
};

export const DefaultDrugAllergy: DrugAllergy = {
  none: false,
  cephalosporins: false,
  clindamycin: false,
  codeine: false,
  demerol: false,
  iodine: false,
  macrolides: false,
  morphine: false,
  nsaidsAspirin: false,
  penicillin: false,
  sulfonamides: false,
  tetracycline: false
};

export type MedicalConditionsFormType = Required<
  Pick<
    MedicalConditions,
    | "none"
    | "alzheimers"
    | "cancer"
    | "cerebrovascularDisease"
    | "chronicLowerRespiratoryDisease"
    | "diabetes"
    | "heartDisease"
    | "influenza"
    | "kidneyDisease"
    | "none"
    | "pneumonia"
    | "septicemia"
  >
>;

export const DefaultMedicalConditions: MedicalConditions = {
  none: false,
  alzheimers: false,
  cancer: false,
  cerebrovascularDisease: false,
  chronicLowerRespiratoryDisease: false,
  diabetes: false,
  heartDisease: false,
  influenza: false,
  kidneyDisease: false,
  pneumonia: false,
  septicemia: false
};

export type PatientFormType = Required<
  Pick<
    MedicalHistory,
    | "firstName"
    | "lastName"
    | "gender"
    | "pregnant"
    | "drugAllergy"
    | "medicalConditions"
    | "otherMedications"
    | "agreedPrivacyPolicy"
    | "agreedTermOfUse"
    | "agreedPharmacyAuth"
  >
  // force birthday to be a string because GraphQL treats ISO8601Datetimes as ``
> & { birthday: string };

export const DefaultPatientForm: PatientFormType = {
  firstName: "",
  lastName: "",
  gender: "",
  birthday: "",
  pregnant: false,
  drugAllergy: DefaultDrugAllergy,
  medicalConditions: DefaultMedicalConditions,
  otherMedications: "",
  agreedPrivacyPolicy: false,
  agreedTermOfUse: false,
  agreedPharmacyAuth: false
};

export type ProductOptions = Array<
  Required<Pick<Product, "id" | "name" | "size" | "price" | "stripeProductId">>
>;

export type SizingFormType = {
  id: string;
};

export const DefaultSizingForm: SizingFormType = {
  id: ""
};

export type ShippingAddressFormType = {
  firstName: string;
  lastName: string;
  company?: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  phone: string;
};

export const DefaultShippingAddressForm: ShippingAddressFormType = {
  firstName: "",
  lastName: "",
  company: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipcode: "",
  country: "US",
  phone: ""
};

export type BillingAddressFormType = {
  firstName: string;
  lastName: string;
  company?: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  phone: string;
  billingSameAsShipping: boolean;
};

export const DefaultBillingAddressForm: BillingAddressFormType = {
  firstName: "",
  lastName: "",
  company: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipcode: "",
  country: "US",
  phone: "",
  billingSameAsShipping: true
};

export type PrescriptionFormType = {
  [PrescriptionTypeKey]: string;
};

export const DefaultPrescriptionForm: PrescriptionFormType = {
  [PrescriptionTypeKey]: ""
};

export type PrescriberFormType = {
  firstName: string;
  lastName: string;
  address1: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  phone: string;
  fax: string;
};

export const DefaultPrescriberForm: PrescriberFormType = {
  firstName: "",
  lastName: "",
  address1: "",
  city: "",
  state: "",
  zipcode: "",
  country: "US",
  phone: "",
  fax: ""
};

export type Order = {
  id?: string;
  state?: OrderState;
  orderNumber?: string;
  orderDate?: string;
  [PaidDateKey]?: string;
  [PaidAmountKey]?: string;
  [PromoCodeKey]?: string;
  [StripeLast4Key]?: string;
  [StripeCardTypeKey]?: string;
  [StripeOrderIDKey]?: string;
  [PatientKey]: PatientFormType;
  [SizingKey]: SizingFormType;
  [ShippingAddressKey]: ShippingAddressFormType;
  [BillingAddressKey]: BillingAddressFormType;
  [PrescriptionKey]: PrescriptionFormType;
  [PrescriberAddressKey]: PrescriberFormType;
};

// Context state type
export type OsteoboostOrderContextType = {
  isLoading: boolean;
  products: ProductOptions;
  order: Order;
  updateOrder: (
    formKey: keyof Order,
    data: any,
    onSuccessfulUpdate?: () => void,
    submitMutation?: boolean
  ) => void;
  refetchOrder: () => Promise<void>;
};

// Default state with initial values
export const defaultState: OsteoboostOrderContextType = {
  isLoading: true,
  products: [],
  order: {
    id: undefined,
    state: undefined,
    orderNumber: undefined,
    orderDate: undefined,
    [StripeLast4Key]: undefined,
    [StripeCardTypeKey]: undefined,
    [StripeOrderIDKey]: undefined,
    [PatientKey]: DefaultPatientForm,
    [SizingKey]: DefaultSizingForm,
    [ShippingAddressKey]: DefaultShippingAddressForm,
    [BillingAddressKey]: DefaultBillingAddressForm,
    [PrescriptionKey]: DefaultPrescriptionForm,
    [PrescriberAddressKey]: DefaultPrescriberForm
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateOrder: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refetchOrder: async () => {}
};

// Create context
export const OsteoboostOrderContext =
  createContext<OsteoboostOrderContextType>(defaultState);

// Hook to use the context
export const useOsteoboostOrder = () => {
  const context = useContext(OsteoboostOrderContext);
  if (!context) {
    throw new Error(
      "useOsteoboostOrder must be used within an OsteoboostOrderProvider"
    );
  }
  return context;
};

export const OsteoboostOrderProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  // HOOKS
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { getOrder, updateOrder: updateOrderMutation } = useOrder();

  // STATE
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>(defaultState.order);
  const [products, setProducts] = useState<ProductOptions>([]);

  const refetchOrder = async () => {
    const { data } = await getOrder();
    const order = data?.getOrder;
    const products = data?.getProducts;
    if (products) {
      setProducts(
        products
          .map(({ id, name, size, price, stripeProductId }) => ({
            id,
            name,
            size,
            price,
            stripeProductId
          }))
          .sort((a, b) => {
            if (a.size === ProductSize.Large) {
              return 1;
            }
            if (b.size === ProductSize.Large) {
              return -1;
            }
            if (a.size === ProductSize.Medium) {
              return 1;
            }
            if (b.size === ProductSize.Medium) {
              return -1;
            }
            return 0;
          })
      );
    }

    setIsLoading(false);
    // merge ordder data with the current order context
    if (order) {
      setOrder((prevOrder) => {
        const newOrder = {
          ...prevOrder,
          id: order.id,
          state: order.state,
          orderNumber: order.orderNumber || undefined,
          orderDate: order[SubmittedDateKey] || undefined,
          [PaidAmountKey]: order[PaidAmountKey] || undefined,
          [PaidDateKey]: order[PaidDateKey] || undefined,
          [PromoCodeKey]: order[PromoCodeKey] || undefined,
          [StripeLast4Key]: order[StripeLast4Key] || undefined,
          [StripeCardTypeKey]: order[StripeCardTypeKey] || undefined,
          [StripeOrderIDKey]: order[StripeOrderIDKey] || undefined,
          [PatientKey]: {
            ...prevOrder[PatientKey],
            ...(order[MedicalHistoryKey]
              ? {
                  firstName: order[MedicalHistoryKey].firstName,
                  lastName: order[MedicalHistoryKey].lastName,
                  birthday: order[MedicalHistoryKey].birthday,
                  gender: order[MedicalHistoryKey].gender,
                  drugAllergy: order[MedicalHistoryKey].drugAllergy,
                  otherMedications: order[MedicalHistoryKey].otherMedications,
                  pregnant: order[MedicalHistoryKey].pregnant,
                  agreedPrivacyPolicy:
                    order[MedicalHistoryKey].agreedPrivacyPolicy,
                  agreedTermOfUse: order[MedicalHistoryKey].agreedTermOfUse,
                  agreedPharmacyAuth:
                    order[MedicalHistoryKey].agreedPharmacyAuth,
                  medicalConditions: {
                    none: order[MedicalHistoryKey].medicalConditions.none,
                    alzheimers:
                      order[MedicalHistoryKey].medicalConditions.alzheimers,
                    cancer: order[MedicalHistoryKey].medicalConditions.cancer,
                    cerebrovascularDisease:
                      order[MedicalHistoryKey].medicalConditions
                        .cerebrovascularDisease,
                    chronicLowerRespiratoryDisease:
                      order[MedicalHistoryKey].medicalConditions
                        .chronicLowerRespiratoryDisease,
                    diabetes:
                      order[MedicalHistoryKey].medicalConditions.diabetes,
                    heartDisease:
                      order[MedicalHistoryKey].medicalConditions.heartDisease,
                    influenza:
                      order[MedicalHistoryKey].medicalConditions.influenza,
                    kidneyDisease:
                      order[MedicalHistoryKey].medicalConditions.kidneyDisease,
                    pneumonia:
                      order[MedicalHistoryKey].medicalConditions.pneumonia,
                    septicemia:
                      order[MedicalHistoryKey].medicalConditions.septicemia
                  }
                }
              : {})
          },
          [SizingKey]: {
            ...prevOrder[SizingKey],
            ...(order[ProductKey] ? { id: order[ProductKey].id } : {})
          },
          [ShippingAddressKey]: {
            ...prevOrder[ShippingAddressKey],
            ...(order[ShippingAddressKey]
              ? {
                  address1: order[ShippingAddressKey].address1,
                  address2: order[ShippingAddressKey].address2 || "",
                  city: order[ShippingAddressKey].city,
                  company: order[ShippingAddressKey].company || "",
                  firstName: order[ShippingAddressKey].firstName,
                  lastName: order[ShippingAddressKey].lastName,
                  phone: order[ShippingAddressKey].phone,
                  zipcode: order[ShippingAddressKey].zipcode,
                  country: order[ShippingAddressKey].country,
                  state: order[ShippingAddressKey].state
                }
              : {})
          },
          [BillingAddressKey]: {
            ...prevOrder[BillingAddressKey],
            ...(order[BillingAddressKey]
              ? {
                  address1: order[BillingAddressKey].address1,
                  address2: order[BillingAddressKey].address2 || "",
                  city: order[BillingAddressKey].city,
                  company: order[BillingAddressKey].company || "",
                  firstName: order[BillingAddressKey].firstName,
                  lastName: order[BillingAddressKey].lastName,
                  phone: order[BillingAddressKey].phone,
                  zipcode: order[BillingAddressKey].zipcode,
                  country: order[BillingAddressKey].country,
                  state: order[BillingAddressKey].state,
                  // if the order billing address is non-nil on fetch we don't
                  // assume the billing address is the same as the shipping
                  // address because this form has already been filled out.
                  //
                  // THis likely means we are reloading the page at this stage and
                  // want to see the information from the database
                  billingSameAsShipping: false
                }
              : {})
          },
          [PrescriptionKey]: {
            ...prevOrder[PrescriptionKey],
            ...(order[PrescriptionTypeKey]
              ? { [PrescriptionTypeKey]: order[PrescriptionTypeKey] }
              : {})
          },
          [PrescriberAddressKey]: {
            ...prevOrder[PrescriberAddressKey],
            ...(order[PrescriberAddressKey]
              ? {
                  address1: order[PrescriberAddressKey].address1,
                  address2: order[PrescriberAddressKey].address2 || "",
                  city: order[PrescriberAddressKey].city,
                  company: order[PrescriberAddressKey].company || "",
                  firstName: order[PrescriberAddressKey].firstName,
                  lastName: order[PrescriberAddressKey].lastName,
                  phone: order[PrescriberAddressKey].phone,
                  fax: order[PrescriberAddressKey].fax || "",
                  zipcode: order[PrescriberAddressKey].zipcode,
                  state: order[PrescriberAddressKey].state,
                  country: order[PrescriberAddressKey].country
                }
              : {})
          }
        };
        return newOrder;
      });

      // Navigate to the payment page if we are on the order complete page but
      // the order is NOT SUBMITTED (might be that the order is canceled)
      if (
        location.pathname === orderRoute(OsteoboostOrderSlug.Complete) &&
        !orderStateSubmittedOrLater(order.state)
      ) {
        navigate(orderRoute(OsteoboostOrderSlug.Payment));
      }

      switch (order.state) {
        case OrderState.Submitted:
          navigate(orderRoute(OsteoboostOrderSlug.Complete));
          break;
        case OrderState.New: // fallthrough
        case OrderState.Canceled: // fallthrough
        case OrderState.PaymentComplete: // fallthrough
        case OrderState.PaymentFailure: // fallthrough
          navigate(orderRoute(OsteoboostOrderSlug.Payment));
          break;
        case OrderState.Draft:
        default:
      }
    }
  };

  // EFFECTS

  // Attempt to load the order once the user is authenticated and on the correct page
  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      refetchOrder()
        .catch((_error) => {
          notify.error("Unable to load your Osteoboost order");
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // Clear the order if the user is not authenticated
    else {
      setOrder(defaultState.order);
    }
  }, [isAuthenticated]);

  const updateOrder = useCallback(
    async (
      formName: keyof Order,
      data: any,
      onSuccessfulUpdate?: () => void,
      submitMutation = true
    ) => {
      // Perform state update synchronously
      setOrder((prevOrder) => ({
        ...prevOrder,
        [formName]: data
      }));

      const key = forKeyRenames.get(formName) || formName;
      let values = data;

      switch (key) {
        case ProductKey:
          values = data["id"];
          break;
        case ShippingAddressKey:
          values["addressType"] = AddressType.Shipping;
          break;
        case BillingAddressKey:
          values["addressType"] = AddressType.Billing;
          delete values["billingSameAsShipping"];
          break;
        case PrescriptionTypeKey:
          values = data["prescriptionType"];
          break;
        case PrescriberAddressKey:
          values["addressType"] = AddressType.Prescriber;
          break;
      }

      if (!submitMutation) {
        return;
      }

      try {
        setIsLoading(true);
        await updateOrderMutation({
          variables: {
            attributes: {
              agreedTermsOfSale: true,
              [key]: values as Order[keyof Order]
            }
          }
        });
        setIsLoading(false);

        if (onSuccessfulUpdate) {
          onSuccessfulUpdate();
        }
      } catch (error) {
        console.error("Order update failed", error);
        notify.error("Failed to update order");
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
    [order, updateOrderMutation]
  );

  const orderContext: OsteoboostOrderContextType = {
    isLoading,
    products: products,
    order,
    updateOrder,
    refetchOrder
  };

  return (
    <OsteoboostOrderContext.Provider value={orderContext}>
      {children}
    </OsteoboostOrderContext.Provider>
  );
};

const orderStateSubmittedOrLater = (state: OrderState): boolean => {
  switch (state) {
    case OrderState.New: // fallthrough

    case OrderState.PaymentComplete: // fallthrough

    case OrderState.PaymentFailure: // fallthrough
    case OrderState.Draft: {
      return false;
    }
    // Submitted or later
    case OrderState.Submitted: // fallthrough

    case OrderState.Enqueued: // fallthrough

    case OrderState.HwCanceled: // fallthrough

    case OrderState.HwComplete: // fallthrough

    case OrderState.HwDispensed: // fallthrough

    case OrderState.HwEnqueued: // fallthrough

    case OrderState.HwFailed: // fallthrough

    case OrderState.HwProcessing: // fallthrough

    case OrderState.HwSubmitted: // fallthrough

    case OrderState.Canceled: {
      return true;
    }
  }
};
